// --------------------------------------------------------------
// Created On: 2021-08-25
// Author: Zachary Thomas
//
// Last Modified: 2025-02-05
// Modified By: Hannah Vaughan
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import { API } from "../../constants/miscellaneous";
import InviteUsersModal from "./InviteUsersModal/InviteUsersModal";
import UserModal from "./UserModal/UserModal";
import UsergroupModal from "./UsergroupModal/UsergroupModal";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { USER_TYPES, USERGROUP_TYPES } from "../../constants/reducerActions";
import ResourceList from "../../components/ResourceList/ResourceList";
import { INVITE_USERS_PERMISSION, CREATE_USER_GROUPS_PERMISSION } from "../../constants/permissions";
import HelpUsergroup from "../../components/HelpUsergroup/HelpUsergroup";
import HelpUser from "../../components/HelpUser/HelpUser";
import UsergroupListRow from "./UsergroupListRow/UsergroupListRow";
import UserListRow from "./UserListRow/UserListRow";
import styles from "./ManageUsersPage.module.scss";
import userHasUserPermissions from "src/utilities/userHasUserPermissions";
import userHasUserGroupPermissions from "src/utilities/userHasUserGroupPermissions";

// Page for creating, editing, and deleting users and user groups.
export default function ManageUsersPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedUsergroup, setSelectedUsergroup] = useState<Usergroup | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [usergroups, dispatchUsergroup] = useReducer(usergroupReducer, []);
  const [users, dispatchUser] = useReducer(userReducer, []);
  const currentUser = useSelector(getCurrentUser);

  // Get user data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/usergroup/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setRoles(responseBody.roles);
        dispatchUsergroup({
          type: USERGROUP_TYPES.SET_USERGROUPS,
          payload: {
            usergroups: responseBody.usergroups,
          },
        });
        dispatchUser({
          type: USER_TYPES.SET_USERS,
          payload: {
            users: responseBody.users,
          },
        });
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Change the currently selected target.
  function updateSelection(selectedId: number, selectedType: string): void {
    setSelectedId(selectedId);
    setSelectedType(selectedType);

    if (selectedType === "usergroup") {
      const usergroup = usergroups.find((usergroup: Usergroup) => usergroup.usergroupId === selectedId);
      if (usergroup === undefined) {
        setSelectedUsergroup({
          usergroupId: 0,
          isDefault: false,
          name: "",
          roleId: 0,
        });
      } else {
        setSelectedUsergroup(usergroup);
      }
    } else if (selectedType === "user") {
      const user = users.find((user) => user.userId === selectedId);
      if (user === undefined) {
        setSelectedUser(null);
      } else {
        setSelectedUser(user);
      }
    }
  }

  // User reducer.
  function userReducer(state: User[], action: Action): User[] {
    switch (action.type) {
      case USER_TYPES.SET_USERS: {
        if (action.payload.users !== undefined) {
          return sortUsers(action.payload.users);
        } else {
          return state;
        }
      }

      case USER_TYPES.CREATE_USER: {
        let stateDeepCopy = deepCopy(state);
        if (action.payload.user !== undefined) {
          const newUser = action.payload.user;
          // If we are re-inviting a user, this will create a user with a new ID. Make sure to clear these users before
          // adding their replacement.
          const emailIndex = stateDeepCopy.findIndex((user) => user.emailAddress === newUser.emailAddress);
          if (emailIndex !== -1) {
            stateDeepCopy.splice(emailIndex, 1);
          }
          const userExists = stateDeepCopy.some((user) => user.userId === newUser.userId);
          if (!userExists) {
            stateDeepCopy.push(newUser);
            stateDeepCopy = sortUsers(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case USER_TYPES.UPDATE_USER: {
        let stateDeepCopy = deepCopy(state);
        if (action.payload.user !== undefined) {
          const updatedUser = action.payload.user;
          const userIndex = stateDeepCopy.findIndex((user) => user.userId === updatedUser.userId);
          if (userIndex === -1) {
            return stateDeepCopy;
          }
          stateDeepCopy.splice(userIndex, 1, updatedUser);
          stateDeepCopy = sortUsers(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case USER_TYPES.DELETE_USER: {
        const stateDeepCopy = deepCopy(state);
        const userId = action.payload.userId;
        const userIndex = stateDeepCopy.findIndex((user) => user.userId === userId);
        if (userIndex === -1) {
          return stateDeepCopy;
        }
        stateDeepCopy.splice(userIndex, 1);
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort users.
  function sortUsers(users: User[]): User[] {
    return users.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      const emailA = a.emailAddress.toUpperCase();
      const emailB = b.emailAddress.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else if (emailA < emailB) {
        return -1;
      } else if (emailA > emailB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // User group reducer.
  function usergroupReducer(state: Usergroup[], action: Action): Usergroup[] {
    switch (action.type) {
      case USERGROUP_TYPES.SET_USERGROUPS: {
        if (action.payload.usergroups !== undefined) {
          return sortUsergroups(action.payload.usergroups);
        } else {
          return state;
        }
      }

      case USERGROUP_TYPES.CREATE_USERGROUP: {
        let stateDeepCopy = deepCopy(state);
        if (action.payload.usergroup !== undefined) {
          const newUsergroup = action.payload.usergroup;

          // If the user group is set to auto-update, remove auto-update from other user groups.
          // Only a single user group should have auto-update capabilities.
          if (newUsergroup.isDefault) {
            stateDeepCopy.forEach((usergroup) => (usergroup.isDefault = false));
          }

          stateDeepCopy.push(newUsergroup);
          stateDeepCopy = sortUsergroups(stateDeepCopy);
        }

        return stateDeepCopy;
      }

      case USERGROUP_TYPES.UPDATE_USERGROUP: {
        let stateDeepCopy = deepCopy(state);

        if (action.payload.usergroup !== undefined) {
          const updatedUsergroup = action.payload.usergroup;

          // If the user group is set to auto-update, remove auto-update from other user groups.
          // Only a single user group should have auto-update capabilities.
          if (updatedUsergroup.isDefault) {
            stateDeepCopy.forEach((usergroup) => (usergroup.isDefault = false));
          }

          const usergroupIndex = stateDeepCopy.findIndex(
            (usergroup) => usergroup.usergroupId === updatedUsergroup.usergroupId
          );
          if (usergroupIndex === -1) {
            return state;
          }
          stateDeepCopy.splice(usergroupIndex, 1, updatedUsergroup);
          stateDeepCopy = sortUsergroups(stateDeepCopy);
        }

        return stateDeepCopy;
      }

      case USERGROUP_TYPES.DELETE_USERGROUP: {
        const stateDeepCopy = deepCopy(state);
        const usergroupId = action.payload.usergroupId;
        const usergroupIndex = stateDeepCopy.findIndex((usergroup) => usergroup.usergroupId === usergroupId);
        if (usergroupIndex === -1) {
          return state;
        }
        stateDeepCopy.splice(usergroupIndex, 1);
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort user groups.
  function sortUsergroups(usergroups: Usergroup[]): Usergroup[] {
    return usergroups.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (a.isDefault) {
        return -1;
      } else if (b.isDefault) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="page-manage-users p-4">
      <Spinner loading={loading} />

      <div className="row">
        {/* User group management. */}
        {userHasUserGroupPermissions() && (
          <div data-test="user-group-resource-list" className={`${styles.list} col-12 col-md mx-0`}>
            <ResourceList
              resourceNameSingular="User Group"
              resourceNamePlural="User Groups"
              resourceArticle="a"
              headerButtonLabel="Create User Group"
              headerButtonLabelSmall="Create"
              headerButtonUserPermissions={[[CREATE_USER_GROUPS_PERMISSION]]}
              resourceIdKey="usergroupId"
              resourcePriorityKey="isDefault"
              resources={usergroups}
              resourceRow={UsergroupListRow}
              helpModal={<HelpUsergroup />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => updateSelection(0, "usergroup")}
              onSelect={(selectedId) => updateSelection(selectedId, "usergroup")}
            />

            {/* User group modal for creating or editing user groups. */}
            {selectedUsergroup !== null && (
              <UsergroupModal
                mode={selectedId > 0 ? "edit" : "create"}
                showModal={selectedType === "usergroup"}
                usergroupId={selectedUsergroup.usergroupId}
                name={selectedUsergroup.name}
                isDefault={selectedUsergroup.isDefault}
                users={users}
                roles={roles}
                roleId={selectedUsergroup.roleId}
                onClose={() => updateSelection(-1, "")}
                onAction={(action) => dispatchUsergroup(action)}
              />
            )}
          </div>
        )}

        {/* User management. */}
        {userHasUserPermissions() && (
          <div data-test="user-resource-list" className={`${styles.list} col-12 col-md mx-0`}>
            <ResourceList
              resourceNameSingular="User"
              resourceNamePlural="Users"
              resourceArticle="a"
              headerButtonLabel="Invite Users"
              headerButtonLabelSmall="Invite"
              headerButtonUserPermissions={[[INVITE_USERS_PERMISSION]]}
              resourceIdKey="userId"
              resources={users}
              resourceRow={UserListRow}
              helpModal={<HelpUser />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => updateSelection(0, "user")}
              onSelect={(selectedId) => updateSelection(selectedId, "user")}
            />

            {/* Modal for inviting new users. */}
            <InviteUsersModal
              showModal={selectedType === "user" && selectedId === 0}
              onAction={(action) => dispatchUser(action)}
              onClose={() => updateSelection(-1, "")}
            />

            {/* User modal for editing users. */}
            {selectedUser !== null && (
              <UserModal
                showModal={selectedType === "user" && selectedId > 0}
                userId={selectedUser.userId}
                name={selectedUser.name}
                emailAddress={selectedUser.emailAddress}
                phoneNumberCountryCode={selectedUser.phoneNumberCountryCode}
                phoneNumber={selectedUser.phoneNumber}
                roleId={selectedUser.roleId}
                roles={roles}
                onClose={() => updateSelection(-1, "")}
                onAction={(action) => dispatchUser(action)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

interface ResponseBody {
  usergroups: Usergroup[];
  users: User[];
  roles: Role[];
}

interface Role {
  roleId: number;
  name: string;
  description: string;
  immutable: boolean;
}

interface Usergroup {
  usergroupId: number;
  name: string;
  isDefault: boolean;
  roleId: number;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
  roleId: number;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  users?: User[];
  user?: User;
  userId?: number;
  usergroups?: Usergroup[];
  usergroup?: Usergroup;
  usergroupId?: number;
}

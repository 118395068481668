// --------------------------------------------------------------
// Created On: 2023-08-19
// Author: Zachary Thomas
//
// Last Modified: 2025-02-10
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { CROSS_COMPANY_PERMISSION } from "../constants/permissions";
import store from "../redux/store";

// Checks if the current user has cross company access.
export default function userHasCrossCompanyAccess(): boolean {
  const storeState = store.getState();

  if (
    storeState !== undefined &&
    storeState.currentUser !== undefined &&
    storeState.currentUser.permissions !== undefined
  ) {
    const currentUserPermissions = storeState.currentUser.permissions;
    return currentUserPermissions.includes(CROSS_COMPANY_PERMISSION);
  }

  return false;
}

// --------------------------------------------------------------
// Created On: 2023-08-14
// Author: Zachary Thomas
//
// Last Modified: 2025-02-12
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useMemo, Fragment } from "react";
import useApi from "../../../hooks/useApi";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { setCurrentUser } from "../../../redux/actions";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import PermissionCheckbox from "./PermissionCheckbox/PermissionCheckbox";
import { API, MAX_ROLE_NAME_LENGTH, MAX_ROLE_DESCRIPTION_LENGTH, RPM_COMPANY } from "../../../constants/miscellaneous";
import { UPDATE_ROLES_PERMISSION, DELETE_ROLES_PERMISSION } from "../../../constants/permissions";
import userHasPermission from "../../../utilities/userHasPermission";
import { ROLE_TYPES } from "../../../constants/reducerActions";
import deepCopy from "../../../utilities/deepCopy";
import styles from "./RoleModal.module.scss";

// Modal for creating, updating, or deleting a role.
export default function RoleModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [previousName, setPreviousName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [previousDescription, setPreviousDescription] = useState<string>("");
  const [permissionIds, setPermissionIds] = useState<number[]>([]);
  const [previousPermissionIds, setPreviousPermissionIds] = useState<number[]>([]);
  const [immutable, setImmutable] = useState<boolean>(false);
  const [permissionsFilter, setPermissionsFilter] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const filteredPermissions = useMemo(
    () => getFilteredPermissions(props.permissions, permissionsFilter),
    [JSON.stringify(props.permissions), permissionsFilter]
  );
  const adminPermissions = useMemo(
    () => getAdminPermissions(props.permissions, permissionsFilter),
    [JSON.stringify(props.permissions), permissionsFilter]
  );

  // Get detailed role information from the API.
  useApi(
    () => {
      setLoading(props.roleId > 0);
      return props.roleId > 0;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/role/${props.roleId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setName(responseBody.name);
        setDescription(responseBody.description);
        setPermissionIds(responseBody.permissionIds);
        setImmutable(responseBody.immutable);
        setPreviousName(responseBody.name);
        setPreviousDescription(responseBody.description);
        setPreviousPermissionIds(responseBody.permissionIds);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get role settings.");
      }
      setLoading(false);
    },
    [props.roleId]
  );

  // Returns a list of all non-admin permissions that match the filter.
  function getFilteredPermissions(permissions: Permission[], filter: string): Permission[] {
    if (filter.length === 0) {
      return permissions.filter((permission) => permission.isAdminPermission === false);
    } else {
      return permissions.filter(
        (permission) =>
          permission.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 && permission.isAdminPermission === false
      );
    }
  }

  // Returns a list of all admin permissions that match the filter.
  function getAdminPermissions(permissions: Permission[], filter: string): Permission[] {
    if (filter.length === 0) {
      return permissions.filter((permission) => permission.isAdminPermission === true);
    } else {
      return permissions.filter(
        (permission) =>
          permission.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 && permission.isAdminPermission === true
      );
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt the user.
  function exitModal(): void {
    let permissionIdsChanged = true;
    if (permissionIds.sort().join(",") === previousPermissionIds.sort().join(",")) {
      permissionIdsChanged = false;
    }

    if (name === previousName && description === previousDescription && !permissionIdsChanged) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges(): void {
    setShowConfirmExit(false);
    setErrorMessage("");
    if (props.isCreatingNewRecord) {
      void createRole();
    } else {
      void editRole(props.roleId);
    }
  }

  // Exit without saving changes.
  function discardChanges(): void {
    props.onClose();
    setShowConfirmDelete(false);
    setShowConfirmExit(false);
    setErrorMessage("");
  }

  // Check if the current role is valid.
  function roleIsValid(): boolean {
    if (name.trim().length === 0) {
      setErrorMessage("A role is required to have a name.");
      return false;
    } else {
      return true;
    }
  }

  // Update permission IDs by either adding or removing a permission depending if it already exists in the array.
  function updatePermissionIds(permissionIds: number[], permissionId: number): void {
    const permissionIdsDeepCopy = deepCopy(permissionIds);
    if (permissionIds.includes(permissionId)) {
      const permissionIndex = permissionIdsDeepCopy.indexOf(permissionId);
      if (permissionIndex > -1) {
        permissionIdsDeepCopy.splice(permissionIndex, 1);
      }
    } else {
      permissionIdsDeepCopy.push(permissionId);
    }
    setPermissionIds(permissionIdsDeepCopy);
  }

  // Select all permissions.
  function selectAllPermissions(): void {
    setPermissionIds(props.permissions.map((permission) => permission.permissionId));
  }

  // Remove all permissions.
  function removeAllPermissions(): void {
    setPermissionIds([]);
  }

  // Create a role.
  async function createRole(): Promise<void> {
    if (roleIsValid()) {
      const requestBody = {
        name: name.trim(),
        description: description,
        permissionIds: permissionIds,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/role`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const newRole = {
          roleId: responseBody.roleId,
          name: name,
          description: description,
          immutable: false,
        };
        setPreviousName(name);
        setPreviousDescription(description);
        setPreviousPermissionIds(permissionIds);
        props.onAction({
          type: ROLE_TYPES.CREATE_ROLE,
          payload: {
            role: newRole,
          },
        });
        setShowConfirmExit(false);
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create role."));
      }
    }
  }

  // Edit a role.
  async function editRole(roleId: number): Promise<void> {
    if (roleIsValid()) {
      const requestBody = {
        name: name.trim(),
        description: description,
        permissionIds: permissionIds,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/role/${roleId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const updatedRole = {
          roleId: roleId,
          name: name.trim(),
          description: description,
          immutable: false,
        };
        setPreviousName(name.trim());
        setPreviousDescription(description);
        setPreviousPermissionIds(permissionIds);
        props.onAction({
          type: ROLE_TYPES.UPDATE_ROLE,
          payload: {
            role: updatedRole,
          },
        });
        setShowConfirmExit(false);
        setErrorMessage("");
        props.onClose();

        // Since a role just got updated, retry getting the user's current permissions as the role could have changed their access.
        setLoading(true);
        const [response, responseBody] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/user/${currentUser.userId}`,
          "GET",
          null
        )) as [Response, UserResponseBody];
        setLoading(false);

        if (response.ok && responseBody) {
          dispatch(
            setCurrentUser(
              currentUser.userId,
              responseBody.name,
              responseBody.emailAddress,
              currentUser.originalCompanyId,
              currentUser.companyId,
              currentUser.companyName,
              currentUser.isCompanyHidden,
              responseBody.permissions,
              currentUser.isPackager
            )
          );
        }
      } else {
        setErrorMessage(await getApiError(response, "Unable to update role."));
      }
    }
  }

  // Delete a role.
  async function deleteRole(roleId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(`${API}/company/${currentUser.companyId}/role/${roleId}`, "DELETE", null)) as [
      Response,
      DeleteResponseBody
    ];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: ROLE_TYPES.DELETE_ROLE,
        payload: {
          roleId: roleId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete role."));
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.isCreatingNewRecord ? "Create Role" : "Edit Role"}</h5>
        </ModalHeader>

        <ModalBody>
          <div className="mx-3 mt-3">
            <label className="mb-3">Name</label>
            <input
              data-test="role-modal-name-input"
              className="form-control mx-auto mb-4"
              type="text"
              maxLength={MAX_ROLE_NAME_LENGTH}
              value={name}
              disabled={immutable || (!userHasPermission([[UPDATE_ROLES_PERMISSION]]) && !props.isCreatingNewRecord)}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-3 mx-3">
            <label className="mb-3">Additional Notes</label>
            <textarea
              data-test="role-modal-name-texarea"
              className="form-control"
              rows={3}
              maxLength={MAX_ROLE_DESCRIPTION_LENGTH}
              value={description}
              disabled={immutable || (!userHasPermission([[UPDATE_ROLES_PERMISSION]]) && !props.isCreatingNewRecord)}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="row">
            <label className="mb-3 mx-3">Selected Permissions</label>
            <div className="col-12 px-4">
              <div className="row px-4 mb-3">
                <div className="col-12 col-lg-8">
                  <input
                    data-test="permission-filter-input"
                    type="search"
                    className="form-control rounded my-2 me-2 d-inline-block"
                    placeholder="Filter results..."
                    value={permissionsFilter}
                    onChange={(e) => setPermissionsFilter(e.target.value)}
                  />
                </div>

                <div className="col-auto">
                  <button
                    className="btn btn-primary my-2 me-2"
                    type="button"
                    disabled={immutable}
                    onClick={() => {
                      selectAllPermissions();
                    }}
                  >
                    Select All
                  </button>

                  <button
                    className="btn btn-primary my-2"
                    type="button"
                    disabled={immutable}
                    onClick={() => removeAllPermissions()}
                  >
                    Select None
                  </button>
                </div>
              </div>
            </div>
            {filteredPermissions.map((permission) => (
              <div
                data-test="role-modal-permission-item"
                className="col-12 col-lg-6 col-xl-4 px-auto"
                key={permission.permissionId}
              >
                <PermissionCheckbox
                  permissionId={permission.permissionId}
                  name={permission.name}
                  description={permission.description}
                  checked={permissionIds.includes(permission.permissionId)}
                  immutable={
                    immutable || (!userHasPermission([[UPDATE_ROLES_PERMISSION]]) && !props.isCreatingNewRecord)
                  }
                  onChange={(permissionId) => updatePermissionIds(permissionIds, permissionId)}
                />
              </div>
            ))}
            {currentUser.companyName === RPM_COMPANY && (
              <Fragment>
                <label className="m-3">Admin Company Exclusive Permissions</label>
                {adminPermissions.map((permission) => (
                  <div
                    data-test="role-modal-admin-permission-item"
                    className="col-12 col-lg-6 col-xl-4 px-auto"
                    key={permission.permissionId}
                  >
                    <PermissionCheckbox
                      permissionId={permission.permissionId}
                      name={permission.name}
                      description={permission.description}
                      checked={permissionIds.includes(permission.permissionId)}
                      immutable={
                        immutable || (!userHasPermission([[UPDATE_ROLES_PERMISSION]]) && !props.isCreatingNewRecord)
                      }
                      onChange={(permissionId) => updatePermissionIds(permissionIds, permissionId)}
                    />
                  </div>
                ))}
              </Fragment>
            )}
          </div>

          {errorMessage.length > 0 && (
            <div className="mt-4">
              <Error message={errorMessage} />
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.isCreatingNewRecord && !immutable ? (
            <Fragment>
              <button
                data-test="role-modal-create-role-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => createRole()}
              >
                Create Role
              </button>

              <button className={`${styles.btn} btn btn-secondary`} type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {!immutable && userHasPermission([[DELETE_ROLES_PERMISSION]]) && (
                <button
                  data-test="role-modal-delete-role-button"
                  className={`${styles.btn} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete Role
                </button>
              )}

              {!immutable && userHasPermission([[UPDATE_ROLES_PERMISSION]]) && (
                <button
                  data-test="role-modal-save-change-button"
                  className={`${styles.btn} btn btn-primary`}
                  type="button"
                  onClick={() => editRole(props.roleId)}
                >
                  Save Changes
                </button>
              )}

              <button
                data-test="role-modal-close-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Close
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={`Delete '${name}'`}
        content={`Are you sure that you want to delete the role '${name}'?`}
        yesText="Delete Role"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteRole(props.roleId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

RoleModal.propTypes = {
  isCreatingNewRecord: PropTypes.bool.isRequired,
  roleId: PropTypes.number.isRequired,
  permissions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  isCreatingNewRecord: boolean;
  roleId: number;
  permissions: Permission[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface Permission {
  permissionId: number;
  code: string;
  name: string;
  description: string;
  isAdminPermission: boolean;
}

interface Role {
  roleId: number;
  name: string;
  description: string;
  immutable: boolean;
}

interface GetResponseBody {
  roleId: number;
  name: string;
  description: string;
  permissionIds: number[];
  immutable: boolean;
}

interface PostResponseBody {
  roleId: number;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface UserResponseBody {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
  companyId: number;
  companyName: string;
  isCompanyHidden: boolean;
  permissions: string[];
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  roles?: Role[];
  role?: Role;
  roleId?: number;
}

// --------------------------------------------------------------
// Created On: 2023-08-19
// Author: Zachary Thomas
//
// Last Modified: 2025-02-12
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import store from "../redux/store";

// Checks if the current user has permission to perform an action.
export default function userHasPermission(requiredPermissions: string[][]): boolean {
  const storeState = store.getState();

  if (
    storeState !== undefined &&
    storeState.currentUser !== undefined &&
    storeState.currentUser.permissions !== undefined
  ) {
    const currentUserPermissions = storeState.currentUser.permissions;

    for (let i = 0; i < requiredPermissions.length; i++) {
      const requiredPermissionSet = requiredPermissions[i];
      let passedChecks = 0;

      for (let j = 0; j < requiredPermissionSet.length; j++) {
        const requiredPermission = requiredPermissionSet[j];
        if (currentUserPermissions.includes(requiredPermission)) {
          passedChecks++;
        }
      }

      if (passedChecks === requiredPermissionSet.length) {
        return true;
      }
    }
  }

  return false;
}

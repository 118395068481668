// --------------------------------------------------------------
// Created On: 2021-10-12
// Author: Zachary Thomas
//
// Last Modified: 2025-02-12
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import {
  API,
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_OVERRIDE_MESSAGE,
  CUSTOMER_SERVICE_PHONE,
} from "../../constants/miscellaneous";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import CompanyList from "./CompanyList/CompanyList";
import userHasPermission from "src/utilities/userHasPermission";
import { CROSS_COMPANY_PERMISSION } from "src/constants/permissions";
import Card from "src/components/Card/Card";
import TextBlurb from "src/components/TextBlurb/TextBlurb";

// Admin dashboard, used for selecting a company to dive into.
export default function DashboardAdminPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  // Get company data from API.
  useApi(
    () => {
      if (userHasPermission([[CROSS_COMPANY_PERMISSION]])) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      url: `${API}/company`,
      method: "GET",
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setCompanies(responseBody.companies);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="page-rpm-dashboard p-4">
      <Spinner loading={loading} />

      {/* List of companies. */}
      {userHasPermission([[CROSS_COMPANY_PERMISSION]]) ? (
        <div className="company-view-container mx-auto">
          <CompanyList companies={companies} />
        </div>
      ) : (
        <Fragment>
          {!loading && (
            <div className="col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2">
              <Card title="Company Access Denied">
                <div className="my-5">
                  <TextBlurb
                    title="It doesn't look like you have permission to go to other companies"
                    paragraph={
                      CUSTOMER_SERVICE_OVERRIDE_MESSAGE === null
                        ? `If you believe this is an error, please contact customer service by email at ${CUSTOMER_SERVICE_EMAIL}` +
                          ` or by phone at ${CUSTOMER_SERVICE_PHONE}.`
                        : `If you believe this is an error, ${CUSTOMER_SERVICE_OVERRIDE_MESSAGE}`
                    }
                    icon="exclamation-triangle"
                  />
                </div>
              </Card>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

interface ResponseBody {
  companies: Company[];
}

interface Company {
  companyId: number;
  name: string;
  code: string;
  isPackager: boolean;
}

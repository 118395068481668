// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2025-02-12
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HamburgerButton from "./HamburgerButton/HamburgerButton";
import NavbarLink from "../NavbarLink/NavbarLink";
import NavbarDropdown from "../NavbarDropdown/NavbarDropdown";
import UserOptions from "./UserOptions/UserOptions";
import CompanyOptions from "./CompanyOptions/CompanyOptions";
import AlertIndicator from "./AlertIndicator/AlertIndicator";
import SearchBar from "../SearchBar/SearchBar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { RPM_COMPANY, PUBLIC_PAGES, APP_BRAND, RPM_BRAND } from "../../constants/miscellaneous";
import PropTypes from "prop-types";
import userHasPermission from "../../utilities/userHasPermission";
import userHasCrossCompanyAccess from "../../utilities/userHasCrossCompanyAccess";
import {
  ALIAS_ATTRIBUTES_PERMISSION,
  CREATE_ASSETS_PERMISSION,
  CREATE_ASSET_GROUPS_PERMISSION,
  CREATE_ASSET_RENTALS_PERMISSION,
  CREATE_REPORTS_PERMISSION,
  DELETE_ASSETS_PERMISSION,
  DELETE_ASSET_GROUPS_PERMISSION,
  REVOKE_ASSET_RENTALS_PERMISSION,
  RETURN_ASSET_RENTALS_PERMISSION,
  DELETE_REPORTS_PERMISSION,
  UPDATE_ASSETS_PERMISSION,
  UPDATE_ASSET_GROUPS_PERMISSION,
  UPDATE_ASSET_RENTALS_PERMISSION,
  UPDATE_REPORTS_PERMISSION,
  VIEW_SUBSCRIPTIONS_PERMISSION,
  VIEW_DEVICE_STATUSES_PERMISSION,
  MANAGE_ALERT_THRESHOLDS_PERMISSION,
  ACCEPT_ASSET_RENTALS_PERMISSION,
  DECLINE_ASSET_RENTALS_PERMISSION,
  TRANSFER_ASSETS_PERMISSION,
  ACCEPT_ASSET_TRANSFERS_PERMISSION,
  DECLINE_ASSET_TRANSFERS_PERMISSION,
  CREATE_TEMPLATES_PERMISSION,
  UPDATE_TEMPLATES_PERMISSION,
  DELETE_TEMPLATES_PERMISSION,
  VIEW_GLOBAL_RPM_REPORTS_PERMISSION,
  CREATE_COMPANY_PERMISSION,
  UPDATE_COMPANY_PERMISSION,
  DELETE_COMPANY_PERMISSION,
} from "../../constants/permissions";
import encodeCompleteUri from "../../utilities/encodeCompleteUri";
import styles from "./Navbar.module.scss";
import userHasUserPermissions from "src/utilities/userHasUserPermissions";
import userHasRolePermissions from "src/utilities/userHasRolePermissions";

// General navbar.
export default function Navbar(props: Props): Component {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inCompany, setInCompany] = useState<boolean>(true);
  const currentUser = useSelector(getCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();

  // Keep track of the status of the current user for routing purposes.
  useEffect(() => {
    setInCompany(currentUser.companyName !== RPM_COMPANY);
  }, [JSON.stringify(currentUser)]);

  // Clear the search query and navigate to the results page.
  function goToResults(resultsAddress: string): void {
    setSearchQuery("");
    navigate(resultsAddress);
  }

  // Gets the list of management pages based on the user's current permissions.
  function getManagementPages(): Page[] {
    const managementPages: Page[] = [];

    if (
      userHasPermission([
        [CREATE_ASSETS_PERMISSION],
        [UPDATE_ASSETS_PERMISSION],
        [DELETE_ASSETS_PERMISSION],
        [CREATE_ASSET_GROUPS_PERMISSION],
        [UPDATE_ASSET_GROUPS_PERMISSION],
        [DELETE_ASSET_GROUPS_PERMISSION],
      ])
    ) {
      managementPages.push({ name: "Assets", targetPage: "/manage/assets" });
    }

    if (
      userHasPermission([[CREATE_TEMPLATES_PERMISSION], [UPDATE_TEMPLATES_PERMISSION], [DELETE_TEMPLATES_PERMISSION]])
    ) {
      managementPages.push({ name: "Templates", targetPage: "/manage/templates" });
    }

    if (userHasUserPermissions()) {
      managementPages.push({ name: "Users", targetPage: "/manage/users" });
    }

    if (userHasRolePermissions()) {
      managementPages.push({ name: "Roles", targetPage: "/manage/roles" });
    }

    if (userHasPermission([[CREATE_REPORTS_PERMISSION], [UPDATE_REPORTS_PERMISSION], [DELETE_REPORTS_PERMISSION]])) {
      managementPages.push({ name: "Reports", targetPage: "/manage/reports" });
    }

    if (
      userHasPermission([
        [CREATE_ASSET_RENTALS_PERMISSION],
        [UPDATE_ASSET_RENTALS_PERMISSION],
        [REVOKE_ASSET_RENTALS_PERMISSION],
        [RETURN_ASSET_RENTALS_PERMISSION],
        [ACCEPT_ASSET_RENTALS_PERMISSION],
        [DECLINE_ASSET_RENTALS_PERMISSION],
      ])
    ) {
      managementPages.push({ name: "Rentals", targetPage: "/manage/rentals" });
    }

    if (
      userHasPermission([
        [TRANSFER_ASSETS_PERMISSION],
        [ACCEPT_ASSET_TRANSFERS_PERMISSION],
        [DECLINE_ASSET_TRANSFERS_PERMISSION],
      ])
    ) {
      managementPages.push({ name: "Transfers", targetPage: "/manage/transfers" });
    }

    managementPages.push({ name: "Views", targetPage: "/manage/views" });

    return managementPages;
  }

  // Gets the list of monitoring device pages based on the user's current permissions.
  function getMonitoringDevicePages(): Page[] {
    const monitoringDevicePages: Page[] = [];

    if (userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION]])) {
      monitoringDevicePages.push({
        name: "Subscriptions",
        targetPage: "/monitoring-devices/subscriptions",
      });
    }

    if (userHasPermission([[VIEW_DEVICE_STATUSES_PERMISSION]])) {
      monitoringDevicePages.push({
        name: "Statuses",
        targetPage: "/monitoring-devices/statuses",
      });
    }

    return monitoringDevicePages;
  }

  // Get the list of data processing pages based on the user's current permissions.
  function getDataProcessingPages(): Page[] {
    const dataProcessingPages: Page[] = [];

    if (userHasPermission([[ALIAS_ATTRIBUTES_PERMISSION]])) {
      dataProcessingPages.push({
        name: "Attribute Aliases",
        targetPage: "/data-processing/attributes",
      });
    }

    dataProcessingPages.push({
      name: "Attribute Information",
      targetPage: "/data-processing/attribute-info",
    });

    if (userHasPermission([[MANAGE_ALERT_THRESHOLDS_PERMISSION]])) {
      dataProcessingPages.push({
        name: "Alert Thresholds",
        targetPage: "/data-processing/alert-thresholds",
      });
    }

    return dataProcessingPages;
  }

  function getSuperAdminPages(): Page[] {
    const superAdminPages: Page[] = [{ name: "Companies", targetPage: "/dashboard/companies" }];
    if (userHasPermission([[VIEW_GLOBAL_RPM_REPORTS_PERMISSION]])) {
      superAdminPages.push({ name: "Chatty Assets", targetPage: "/assets/chatty" });
    }
    if (userHasPermission([[CREATE_COMPANY_PERMISSION], [UPDATE_COMPANY_PERMISSION], [DELETE_COMPANY_PERMISSION]])) {
      superAdminPages.push({ name: "Manage Companies", targetPage: "/manage/companies" });
    }
    if (userHasUserPermissions()) {
      superAdminPages.push({ name: "Manage Users", targetPage: "/manage/users" });
    }
    if (userHasRolePermissions()) {
      superAdminPages.push({ name: "Manage Roles", targetPage: "/manage/roles" });
    }
    if (userHasPermission([[VIEW_GLOBAL_RPM_REPORTS_PERMISSION]])) {
      superAdminPages.push({ name: "Reports", targetPage: "/reports" });
    }
    return superAdminPages;
  }

  // Select a logo based on the passed in app logo environment variable.
  function getLogoUrl(): string {
    if (APP_BRAND === RPM_BRAND) {
      return "/logo.svg";
    } else {
      return "/logo.png";
    }
  }

  return (
    <nav className={`${styles.body} navbar navbar-expand-xl px-3`}>
      <div className="w-100">
        <div className="row align-items-center">
          <div className={`${styles.start} col-auto`}>
            {/* Hamburger menu button. */}
            <HamburgerButton onClick={() => props.onOpenNavigationDrawer()} />

            {/* RPM logo. */}
            <Link className={`${styles.brand} p-0 m-0`} to={PUBLIC_PAGES.includes(props.currentPage) ? "/login" : "/"}>
              <img className={`${styles.logo} m-0`} src={getLogoUrl()} alt="RPM Squared" />
            </Link>
          </div>

          {/* Navigation links. */}
          {PUBLIC_PAGES.includes(props.currentPage) ? (
            <div className={`${styles.linkList} col me-auto`}>
              <ul className="navbar-nav d-none d-xl-flex">
                <NavbarLink name="Login" targetPage="/login" currentPage={props.currentPage} />
                <NavbarLink name="Create Account" targetPage="/register" currentPage={props.currentPage} />
                <NavbarLink name="Reset Password" targetPage="/forgot-password" currentPage={props.currentPage} />
                {props.currentPage === "/change-temporary-password" && (
                  <NavbarLink
                    name="Change Password"
                    targetPage="/change-temporary-password"
                    currentPage={props.currentPage}
                  />
                )}
                {props.currentPage === "/change-temp-password" && (
                  <NavbarLink
                    name="Change Password"
                    targetPage="/change-temp-password"
                    currentPage={props.currentPage}
                  />
                )}
                {props.currentPage === "/accept-invite" && (
                  <NavbarLink name="Company Invite" targetPage="/accept-invite" currentPage={props.currentPage} />
                )}

                {props.currentPage === "/privacy-policy-viewer" && (
                  <NavbarLink
                    name="Privacy Policy"
                    targetPage="/privacy-policy-viewer"
                    currentPage={props.currentPage}
                  />
                )}

                {props.currentPage === "/privacy-policy-text" && (
                  <NavbarLink name="Privacy Policy" targetPage="/privacy-policy-text" currentPage={props.currentPage} />
                )}

                {props.currentPage === "/eula-viewer" && (
                  <NavbarLink name="EULA" targetPage="/eula-viewer" currentPage={props.currentPage} />
                )}

                {props.currentPage === "/eula-text" && (
                  <NavbarLink name="EULA" targetPage="/eula-text" currentPage={props.currentPage} />
                )}
              </ul>
            </div>
          ) : (
            <Fragment>
              <div className={`${styles.linkList} col me-auto`}>
                <ul className="navbar-nav d-none d-xl-flex">
                  {inCompany ? (
                    <Fragment>
                      {!currentUser.isPackager && (
                        <NavbarDropdown
                          name="Data Dashboard"
                          targetPage="/dashboard"
                          currentPage={props.currentPage}
                          menuItems={[
                            { name: "Asset Map", targetPage: "/dashboard/map" },
                            { name: "Asset List", targetPage: "/dashboard/list" },
                            { name: "Asset Graph", targetPage: "/dashboard/graph" },
                          ]}
                        />
                      )}

                      <NavbarDropdown
                        name="Manage Resources"
                        targetPage="/manage"
                        currentPage={props.currentPage}
                        menuItems={getManagementPages()}
                      />

                      {getDataProcessingPages().length > 0 && (
                        <NavbarDropdown
                          name="Data Processing"
                          targetPage="/data-processing"
                          currentPage={props.currentPage}
                          menuItems={getDataProcessingPages()}
                        />
                      )}

                      {getMonitoringDevicePages().length > 0 && (
                        <NavbarDropdown
                          name="Monitoring Devices"
                          targetPage="/monitoring-devices"
                          currentPage={props.currentPage}
                          menuItems={getMonitoringDevicePages()}
                        />
                      )}

                      {props.currentPage === "/asset" && (
                        <NavbarLink name="Asset Details" currentPage={location.pathname} />
                      )}

                      {props.currentPage === "/scan-asset" && (
                        <NavbarLink name="Process QR Code" currentPage={location.pathname} />
                      )}

                      {props.currentPage === "/review-account" && (
                        <NavbarLink name="Review Request" currentPage={location.pathname} />
                      )}

                      {props.currentPage === "/asset-review" && (
                        <NavbarLink name="Review Request" currentPage={location.pathname} />
                      )}
                    </Fragment>
                  ) : (
                    <NavbarDropdown
                      name="RPM Dashboard"
                      targetPage="/dashboard"
                      currentPage={props.currentPage}
                      menuItems={getSuperAdminPages()}
                    />
                  )}

                  {props.currentPage === "/search" && <NavbarLink name="Search" currentPage={location.pathname} />}

                  {props.currentPage === "/alert" && (
                    <NavbarLink name="Alerts" targetPage="/alert" currentPage={props.currentPage} />
                  )}

                  {props.currentPage === "/account" && (
                    <NavbarLink name="Account Settings" targetPage="/account" currentPage={props.currentPage} />
                  )}
                </ul>
              </div>

              {/* Search bar. */}
              {currentUser.companyName !== RPM_COMPANY && !currentUser.isPackager && (
                <div className="col-auto ps-0">
                  <SearchBar
                    prompt="Search assets..."
                    location="NAVBAR"
                    searchQuery={searchQuery}
                    onChange={(searchQuery) => setSearchQuery(searchQuery)}
                    onSubmit={() => goToResults(`/search/${encodeCompleteUri(searchQuery)}`)}
                  />
                </div>
              )}

              <div className={`${styles.extraOptions} col-auto`}>
                <div className="row align-items-center">
                  {/* Alert indicator that polls periodically. */}
                  <div className="col px-0">
                    <AlertIndicator />
                  </div>

                  {/* Company information (for RPM admins). */}
                  {userHasCrossCompanyAccess() && currentUser.companyName !== RPM_COMPANY && (
                    <div className="col px-0">
                      <CompanyOptions />
                    </div>
                  )}

                  {/* Menu of user options. */}
                  <div className="col ps-0">
                    <UserOptions />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  currentPage: PropTypes.string.isRequired,
  onOpenNavigationDrawer: PropTypes.func.isRequired,
};

interface Page {
  name: string;
  targetPage: string;
}

interface Props {
  currentPage: string;
  onOpenNavigationDrawer: () => void;
}

// --------------------------------------------------------------
// Created On: 2023-08-14
// Author: Zachary Thomas
//
// Last Modified: 2025-01-29
// Modified By: Hannah Vaughan
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import ResourceList from "../../components/ResourceList/ResourceList";
import RoleModal from "./RoleModal/RoleModal";
import { API } from "../../constants/miscellaneous";
import { ROLE_TYPES } from "../../constants/reducerActions";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import RoleListRow from "./RoleListRow/RoleListRow";
import HelpRole from "../../components/HelpRole/HelpRole";
import { CREATE_ROLES_PERMISSION } from "../../constants/permissions";
import styles from "./ManageRolesPage.module.scss";

// Page for creating, editing, and deleting roles.
export default function ManageRolesPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [roles, dispatch] = useReducer(roleReducer, []);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const currentUser = useSelector(getCurrentUser);

  // Get a list of roles and permissions from the API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/role`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch({
          type: ROLE_TYPES.SET_ROLES,
          payload: {
            roles: responseBody.roles,
          },
        });
        setPermissions(responseBody.permissions);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Role reducer.
  function roleReducer(state: Role[], action: Action): Role[] {
    switch (action.type) {
      case ROLE_TYPES.SET_ROLES: {
        if (action.payload.roles !== undefined) {
          return sortRoles(action.payload.roles);
        } else {
          return [];
        }
      }

      case ROLE_TYPES.CREATE_ROLE: {
        let stateDeepCopy = deepCopy(state);
        const newRole = action.payload.role;
        if (newRole !== undefined) {
          const roleExists = stateDeepCopy.some((role) => role.roleId === newRole.roleId);
          if (!roleExists) {
            stateDeepCopy.push(newRole);
            stateDeepCopy = sortRoles(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case ROLE_TYPES.UPDATE_ROLE: {
        let stateDeepCopy = deepCopy(state);
        const updatedRole = action.payload.role;
        if (updatedRole !== undefined) {
          const roleIndex = stateDeepCopy.findIndex((role) => role.roleId === updatedRole.roleId);
          if (roleIndex === -1) {
            return stateDeepCopy;
          }
          stateDeepCopy.splice(roleIndex, 1, updatedRole);
          stateDeepCopy = sortRoles(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case ROLE_TYPES.DELETE_ROLE: {
        const stateDeepCopy = deepCopy(state);
        const roleId = action.payload.roleId;
        if (roleId !== undefined) {
          const roleIndex = stateDeepCopy.findIndex((role) => role.roleId === roleId);
          if (roleIndex > -1) {
            stateDeepCopy.splice(roleIndex, 1);
          }
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort roles.
  function sortRoles(roles: Role[]): Role[] {
    return roles.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row">
        <div className={`${styles.list} col-12 mx-0`}>
          <ResourceList
            resourceNameSingular="Role"
            resourceNamePlural="Roles"
            resourceArticle="a"
            headerButtonLabel="Create Role"
            headerButtonLabelSmall="Create"
            headerButtonUserPermissions={[[CREATE_ROLES_PERMISSION]]}
            resourceIdKey="roleId"
            resourcePriorityKey="immutable"
            resources={roles}
            resourceRow={RoleListRow}
            helpModal={<HelpRole />}
            filterKeys={["name"]}
            loading={loading}
            onClickHeaderButton={() => setSelectedId(0)}
            onSelect={(selectedId) => setSelectedId(selectedId)}
          />

          {selectedId >= 0 && (
            <RoleModal
              isCreatingNewRecord={selectedId === 0}
              roleId={selectedId}
              permissions={permissions}
              onClose={() => setSelectedId(-1)}
              onAction={(action) => dispatch(action)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

interface ResponseBody {
  roles: Role[];
  permissions: Permission[];
}

interface Role {
  roleId: number;
  name: string;
  description: string;
  immutable: boolean;
}

interface Permission {
  permissionId: number;
  code: string;
  name: string;
  description: string;
  isAdminPermission: boolean;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  roles?: Role[];
  role?: Role;
  roleId?: number;
}

// --------------------------------------------------------------
// Created On: 2025-02-05
// Author: Hannah Vaughan
//
// Last Modified: 2025-02-05
// Modified By: Hannah Vaughan
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { RPM_COMPANY } from "src/constants/miscellaneous";
import store from "../redux/store";
import userHasPermission from "./userHasPermission";
import {
  CREATE_USER_GROUPS_PERMISSION,
  DELETE_ADMIN_COMPANY_USER_PERMISSION,
  DELETE_USER_GROUPS_PERMISSION,
  INVITE_ADMIN_COMPANY_USER_PERMISSION,
  UPDATE_ADMIN_COMPANY_USER_PERMISSION,
  UPDATE_USER_GROUPS_PERMISSION,
} from "src/constants/permissions";

// Checks if the current user has permission to access the user groups portion of the Manage Users page.
export default function userHasUserGroupPermissions(): boolean {
  let userHasPermissions = false;
  const storeState = store.getState();

  if (
    storeState !== undefined &&
    storeState.currentUser !== undefined &&
    storeState.currentUser.permissions !== undefined
  ) {
    if (storeState.currentUser.companyName === RPM_COMPANY) {
      if (
        userHasPermission([
          [INVITE_ADMIN_COMPANY_USER_PERMISSION],
          [UPDATE_ADMIN_COMPANY_USER_PERMISSION],
          [DELETE_ADMIN_COMPANY_USER_PERMISSION],
        ])
      ) {
        userHasPermissions = true;
      }
    } else {
      if (
        userHasPermission([
          [CREATE_USER_GROUPS_PERMISSION],
          [UPDATE_USER_GROUPS_PERMISSION],
          [DELETE_USER_GROUPS_PERMISSION],
        ])
      ) {
        userHasPermissions = true;
      }
    }
  }

  return userHasPermissions;
}

// --------------------------------------------------------------
// Created On: 2023-01-27
// Author: Zachary Thomas
//
// Last Modified: 2025-02-10
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import useApi from "../../../hooks/useApi";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import PropTypes from "prop-types";
import ReportAttributeForm from "./ReportAttributeForm/ReportAttributeForm";
import ReportEmailForm from "./ReportEmailForm/ReportEmailForm";
import ReportAssetgroupForm from "./ReportAssetgroupForm/ReportAssetgroupForm";
import ScheduleForm from "./ScheduleForm/ScheduleForm";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import deepCopy from "../../../utilities/deepCopy";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import scheduleFromLocalToUtc from "../../../utilities/time/scheduleFromLocalToUtc";
import scheduleFromUtcToLocal from "../../../utilities/time/scheduleFromUtcToLocal";
import {
  API,
  MAX_REPORT_NAME_LENGTH,
  MAX_REPORT_ATTRIBUTES,
  REPORT_MODE_CURRENT,
  REPORT_MODE_SERIES,
  REPORT_MODE_DIFFERENCE,
  REPORT_ASSET_ALL,
  REPORT_ASSET_BY_GROUP,
  SCHEDULE_TYPE_DAILY,
  VALID_EMAIL_REGULAR_EXPRESSION,
} from "../../../constants/miscellaneous";
import userHasCrossCompanyAccess from "../../../utilities/userHasCrossCompanyAccess";
import { REPORT_TYPES } from "../../../constants/reducerActions";
import userHasPermission from "../../../utilities/userHasPermission";
import {
  CREATE_REPORTS_PERMISSION,
  DELETE_REPORTS_PERMISSION,
  UPDATE_REPORTS_PERMISSION,
} from "../../../constants/permissions";
import styles from "./ReportModal.module.scss";

// Modal for creating, updating, or deleting a report.
export default function ReportModal(props: Props): Component {
  const initialReport: AssetReport = {
    reportId: 0,
    name: "",
    enabled: true,
    type: REPORT_MODE_CURRENT,
    hours: 1,
    attributes: [],
    assetgroups: [],
    emailAddresses: [],
    scheduleSettings: [{ reportScheduleId: 1, type: SCHEDULE_TYPE_DAILY, value: "09:00" }],
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [previousReport, setPreviousReport] = useState<AssetReport>(initialReport);
  const [name, setName] = useState<string>("");
  const [enabled, setEnabled] = useState<boolean>(true);
  const [type, setType] = useState<string>(REPORT_MODE_CURRENT);
  const [assetSelectionType, setAssetSelectionType] = useState<string>(REPORT_ASSET_ALL);
  const [hours, setHours] = useState<number>(1);
  const [attributes, setAttributes] = useState<FormAttribute[]>([]);
  const [assetgroups, setAssetgroups] = useState<FormAssetgroup[]>([]);
  const [emailAddresses, setEmailAddresses] = useState<FormEmailAddress[]>([]);
  const [scheduleSettings, setScheduleSettings] = useState<ReportSchedule[]>(initialReport.scheduleSettings);
  const currentUser = useSelector(getCurrentUser);

  // Get detailed report information from the API.
  useApi(
    () => {
      setLoading(props.reportId > 0);
      return props.reportId > 0;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/report/${props.reportId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        const scheduleSettings = scheduleFromUtcToLocal(responseBody.scheduleSettings);
        if (scheduleSettings !== null) {
          const report = {
            reportId: responseBody.reportId,
            name: responseBody.name,
            enabled: responseBody.enabled,
            type: responseBody.type,
            hours: responseBody.hours,
            attributes: responseBody.attributes,
            assetgroups: responseBody.assetgroups,
            emailAddresses: responseBody.emailAddresses,
            scheduleSettings: scheduleSettings,
          };
          const formAttributes = responseBody.attributes.map((attribute, i) => {
            return { formId: i + 1, attributeId: attribute.attributeId, name: attribute.name };
          });
          const formAssetgroups = responseBody.assetgroups.map((assetgroup, i) => {
            return { formId: i + 1, assetgroupId: assetgroup.assetgroupId, name: assetgroup.name };
          });
          const formEmailAddresses = responseBody.emailAddresses.map((emailAddress, i) => {
            return { formId: i + 1, value: emailAddress };
          });
          let tempAssetSelectionType = REPORT_ASSET_ALL;
          if (!responseBody.allAssets) {
            tempAssetSelectionType = REPORT_ASSET_BY_GROUP;
          }
          setPreviousReport(report);
          setName(responseBody.name);
          setEnabled(responseBody.enabled);
          setType(responseBody.type);
          setHours(responseBody.hours);
          setAssetSelectionType(tempAssetSelectionType);
          setAttributes(formAttributes);
          setAssetgroups(formAssetgroups);
          setEmailAddresses(formEmailAddresses);
          setScheduleSettings(scheduleSettings);
          setErrorMessage("");
        } else {
          setErrorMessage("Internal server error. Unable to get report settings.");
        }
      } else {
        setErrorMessage("Internal server error. Unable to get report settings.");
      }
      setLoading(false);
    },
    [props.reportId]
  );

  // Exit modal if no changes have been made. Otherwise prompt the user.
  function exitModal(): void {
    // Get comparable current and previous email addresses.
    const emailAddressValues = emailAddresses.map((emailAddress) => emailAddress.value);
    let previousEmailAddressValues: string[] = [];
    if (previousReport.emailAddresses !== undefined) {
      previousEmailAddressValues = previousReport.emailAddresses;
    }

    // Get comparable current and previous attributes.
    const attributeIds = attributes.map((attribute) => attribute.attributeId);
    let previousAttributeIds: number[] = [];
    if (previousReport.attributes !== undefined) {
      previousAttributeIds = previousReport.attributes.map((attribute) => attribute.attributeId);
    }

    // Get comparable current and previous asset groups.
    const assetgroupIds = assetgroups.map((assetgroup) => assetgroup.assetgroupId);
    let previousAssetgroupIds: number[] = [];
    if (previousReport.assetgroups !== undefined) {
      previousAssetgroupIds = previousReport.assetgroups.map((assetgroup) => assetgroup.assetgroupId);
    }

    // Get comparable current and previous schedules.
    const tempScheduleSettings = scheduleSettings.map((scheduleSetting, i) => {
      return {
        reportScheduleId: i + 1,
        type: scheduleSetting.type,
        value: scheduleSetting.value,
      };
    });
    let previousScheduleSettings: ReportSchedule[] = [];
    if (previousReport.scheduleSettings !== undefined) {
      previousScheduleSettings = previousReport.scheduleSettings.map((scheduleSetting, i) => {
        return {
          reportScheduleId: i + 1,
          type: scheduleSetting.type,
          value: scheduleSetting.value,
        };
      });
    }

    // Check to see if anything has changed in the report settings.
    if (
      name === previousReport.name &&
      enabled === previousReport.enabled &&
      type === previousReport.type &&
      hours === previousReport.hours &&
      JSON.stringify(emailAddressValues) === JSON.stringify(previousEmailAddressValues) &&
      JSON.stringify(attributeIds) === JSON.stringify(previousAttributeIds) &&
      JSON.stringify(assetgroupIds) === JSON.stringify(previousAssetgroupIds) &&
      JSON.stringify(tempScheduleSettings) === JSON.stringify(previousScheduleSettings)
    ) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges(): void {
    setShowConfirmExit(false);
    setErrorMessage("");
    if (props.isCreatingNewRecord) {
      void createReport();
    } else {
      void editReport(props.reportId);
    }
  }

  // Exit without saving changes.
  function discardChanges(): void {
    props.onClose();
    setShowConfirmDelete(false);
    setShowConfirmExit(false);
    setErrorMessage("");
  }

  // Add an empty email address.
  function addEmailAddress(): void {
    const emailAddressDeepCopy = deepCopy(emailAddresses);
    let formId = 1;
    emailAddressDeepCopy.forEach((emailAddress) => {
      if (emailAddress.formId >= formId) {
        formId = emailAddress.formId + 1;
      }
    });
    const emailAddress = {
      formId: formId,
      value: "",
    };
    emailAddressDeepCopy.push(emailAddress);
    setEmailAddresses(emailAddressDeepCopy);
  }

  // Update an email address.
  function updateEmailAddress(formId: number, value: string): void {
    const emailAddressDeepCopy = deepCopy(emailAddresses);
    const emailAddress = emailAddressDeepCopy.find((emailAddress) => emailAddress.formId === formId);
    if (emailAddress !== undefined) {
      emailAddress.value = value;
      setEmailAddresses(emailAddressDeepCopy);
    }
  }

  // Delete an email address.
  function deleteEmailAddress(formId: number): void {
    const emailAddressesDeepCopy = deepCopy(emailAddresses);
    const index = emailAddressesDeepCopy.findIndex((emailAddress) => emailAddress.formId === formId);
    if (index >= 0) {
      emailAddressesDeepCopy.splice(index, 1);
      setEmailAddresses(emailAddressesDeepCopy);
    }
  }

  // Add an empty attribute.
  function addAttribute(): void {
    const attributesDeepCopy = deepCopy(attributes);
    let formId = 1;
    attributesDeepCopy.forEach((attribute) => {
      if (attribute.formId >= formId) {
        formId = attribute.formId + 1;
      }
    });
    const attribute = {
      formId: formId,
      attributeId: 0,
      name: "",
    };
    attributesDeepCopy.push(attribute);
    setAttributes(attributesDeepCopy);
  }

  // Update an attribute.
  function updateAttribute(formId: number, name: string): void {
    const attributesDeepCopy = deepCopy(attributes);
    const attribute = attributesDeepCopy.find((attribute) => attribute.formId === formId);
    if (attribute !== undefined) {
      const lookUpAttribute = props.attributes.find(
        (lookUpAttribute) => lookUpAttribute.name.toLowerCase() === name.toLowerCase()
      );
      if (lookUpAttribute !== undefined) {
        attribute.attributeId = lookUpAttribute.attributeId;
      } else {
        attribute.attributeId = 0;
      }
      attribute.name = name;
      setAttributes(attributesDeepCopy);
    }
  }

  // Delete an attribute.
  function deleteAttribute(formId: number): void {
    const attributesDeepCopy = deepCopy(attributes);
    const index = attributesDeepCopy.findIndex((attribute) => attribute.formId === formId);
    if (index >= 0) {
      attributesDeepCopy.splice(index, 1);
      setAttributes(attributesDeepCopy);
    }
  }

  // Add an empty asset group.
  function addAssetgroup(): void {
    const assetgroupsDeepCopy = deepCopy(assetgroups);
    let formId = 1;
    assetgroupsDeepCopy.forEach((assetgroup) => {
      if (assetgroup.formId >= formId) {
        formId = assetgroup.formId + 1;
      }
    });
    const assetgroup = {
      formId: formId,
      assetgroupId: 0,
      name: "",
    };
    assetgroupsDeepCopy.push(assetgroup);
    setAssetgroups(assetgroupsDeepCopy);
  }

  // Update an asset group.
  function updateAssetgroup(formId: number, name: string): void {
    const assetgroupsDeepCopy = deepCopy(assetgroups);
    const assetgroup = assetgroupsDeepCopy.find((assetgroup) => assetgroup.formId === formId);
    if (assetgroup !== undefined) {
      const lookUpAssetgroup = props.assetgroups.find(
        (lookUpAssetgroup) => lookUpAssetgroup.name.toLowerCase() === name.toLowerCase()
      );
      if (lookUpAssetgroup !== undefined) {
        assetgroup.assetgroupId = lookUpAssetgroup.assetgroupId;
      } else {
        assetgroup.assetgroupId = 0;
      }
      assetgroup.name = name;
      setAssetgroups(assetgroupsDeepCopy);
    }
  }

  // Delete an asset group.
  function deleteAssetgroup(formId: number): void {
    const assetgroupsDeepCopy = deepCopy(assetgroups);
    const index = assetgroupsDeepCopy.findIndex((assetgroup) => assetgroup.formId === formId);
    if (index >= 0) {
      assetgroupsDeepCopy.splice(index, 1);
      setAssetgroups(assetgroupsDeepCopy);
    }
  }

  // Check if the current report is valid.
  function reportIsValid(): boolean {
    // Check each element of arrays to see if an attribute was not correctly entered by the user.
    let invalidEmailIndex = -1;
    let invalidAttributeIndex = -1;
    let invalidAssetgroupIndex = -1;
    emailAddresses.forEach((emailAddress, i) => {
      if (!VALID_EMAIL_REGULAR_EXPRESSION.test(emailAddress.value.toLowerCase())) {
        invalidEmailIndex = i;
      }
    });
    attributes.forEach((attribute, i) => {
      if (attribute.attributeId === 0) {
        invalidAttributeIndex = i;
      }
    });
    assetgroups.forEach((assetgroup, i) => {
      if (assetgroup.assetgroupId === 0) {
        invalidAssetgroupIndex = i;
      }
    });

    if (name.trim().length === 0) {
      setErrorMessage("A report is required to have a name.");
      return false;
    } else if (assetgroups.length === 0 && assetSelectionType === REPORT_ASSET_BY_GROUP) {
      setErrorMessage("A report must include at least one asset group.");
      return false;
    } else if (attributes.length === 0) {
      setErrorMessage("A report must include at least one attribute.");
      return false;
    } else if (scheduleSettings.length === 0) {
      setErrorMessage("The entered schedule is not valid.");
      return false;
    } else if (invalidEmailIndex >= 0) {
      setErrorMessage(`Email address #${invalidEmailIndex + 1} is invalid.`);
      return false;
    } else if (invalidAttributeIndex >= 0) {
      setErrorMessage(`Attribute #${invalidAttributeIndex + 1} is invalid.`);
      return false;
    } else if (invalidAssetgroupIndex >= 0) {
      setErrorMessage(`Asset group #${invalidAssetgroupIndex + 1} is invalid.`);
      return false;
    } else {
      return true;
    }
  }

  // Create a report.
  async function createReport(): Promise<void> {
    if (reportIsValid()) {
      const utcScheduleSettings = scheduleFromLocalToUtc(scheduleSettings);

      if (utcScheduleSettings !== null) {
        const attributeIds = attributes.map((attribute) => attribute.attributeId);
        const assetgroupIds = assetgroups.map((assetgroup) => assetgroup.assetgroupId);
        const emailAddressValues = emailAddresses.map((emailAddress) => emailAddress.value);

        const noDuplicateAttributeIds = attributeIds.filter(
          (attributeId, index) => attributeIds.indexOf(attributeId) === index
        );

        const noDuplicateEmailAddressValues = emailAddressValues.filter(
          (emailAddressValue, index) => emailAddressValues.indexOf(emailAddressValue) === index
        );

        // Only include asset groups in the request if we selected the option for asset groups.
        let noDuplicateAssetgroupIds: number[] = [];
        if (assetSelectionType === REPORT_ASSET_BY_GROUP) {
          noDuplicateAssetgroupIds = assetgroupIds.filter(
            (assetgroupId, index) => assetgroupIds.indexOf(assetgroupId) === index
          );
        }

        // Send the correct asset selection type.
        const allAssets = assetSelectionType === REPORT_ASSET_ALL;

        const requestBody = {
          name: name.trim(),
          reportEnabled: enabled,
          reportType: type,
          hours: hours,
          selectedAttributeIds: noDuplicateAttributeIds,
          emailAddresses: noDuplicateEmailAddressValues,
          scheduleSettings: utcScheduleSettings,
          assetgroupIds: noDuplicateAssetgroupIds,
          allAssets: allAssets,
        };

        setLoading(true);
        const [response, responseBody] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/report`,
          "POST",
          requestBody
        )) as [Response, PostResponseBody];
        setLoading(false);

        if (response.ok) {
          const reportDeepCopy = deepCopy(previousReport);
          reportDeepCopy.reportId = responseBody.reportId;
          reportDeepCopy.name = name;
          setPreviousReport(reportDeepCopy);
          props.onAction({
            type: REPORT_TYPES.CREATE_REPORT,
            payload: {
              report: reportDeepCopy,
            },
          });
          setShowConfirmExit(false);
          setErrorMessage("");
          props.onClose();
        } else {
          setErrorMessage(await getApiError(response, "Unable to create report."));
        }
      } else {
        setErrorMessage("Invalid date entered.");
      }
    }
  }

  // Edit a report.
  async function editReport(reportId: number): Promise<void> {
    if (reportIsValid()) {
      const utcScheduleSettings = scheduleFromLocalToUtc(scheduleSettings);

      if (utcScheduleSettings !== null) {
        const attributeIds = attributes.map((attribute) => attribute.attributeId);
        const assetgroupIds = assetgroups.map((assetgroup) => assetgroup.assetgroupId);
        const emailAddressValues = emailAddresses.map((emailAddress) => emailAddress.value);

        const noDuplicateAttributeIds = attributeIds.filter(
          (attributeId, index) => attributeIds.indexOf(attributeId) === index
        );
        const noDuplicateEmailAddressValues = emailAddressValues.filter(
          (emailAddressValue, index) => emailAddressValues.indexOf(emailAddressValue) === index
        );

        // Only include asset groups in the request if we selected the option for asset groups.
        let noDuplicateAssetgroupIds: number[] = [];
        if (assetSelectionType === REPORT_ASSET_BY_GROUP) {
          noDuplicateAssetgroupIds = assetgroupIds.filter(
            (assetgroupId, index) => assetgroupIds.indexOf(assetgroupId) === index
          );
        }

        // Send the correct selection type.
        const allAssets = assetSelectionType === REPORT_ASSET_ALL;

        const requestBody = {
          name: name.trim(),
          reportEnabled: enabled,
          reportType: type,
          hours: hours,
          selectedAttributeIds: noDuplicateAttributeIds,
          emailAddresses: noDuplicateEmailAddressValues,
          scheduleSettings: utcScheduleSettings,
          assetgroupIds: noDuplicateAssetgroupIds,
          allAssets: allAssets,
        };

        setLoading(true);
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/report/${reportId}`,
          "PUT",
          requestBody
        )) as [Response, PutResponseBody];
        setLoading(false);

        if (response.ok) {
          const reportDeepCopy = deepCopy(previousReport);
          reportDeepCopy.reportId = reportId;
          reportDeepCopy.name = name;
          setPreviousReport(reportDeepCopy);
          props.onAction({
            type: REPORT_TYPES.UPDATE_REPORT,
            payload: {
              report: reportDeepCopy,
            },
          });
          setErrorMessage("");
          props.onClose();
        } else {
          setErrorMessage(await getApiError(response, "Unable to update report."));
        }
      } else {
        setErrorMessage("Invalid date entered.");
      }
    }
  }

  // Delete a report.
  async function deleteReport(reportId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/report/${reportId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: REPORT_TYPES.DELETE_REPORT,
        payload: {
          reportId: reportId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete report."));
    }
  }

  // Returns whether the current user is allowed to edit the form with their current permissions.
  function formIsEditable(): boolean {
    return (
      (props.isCreatingNewRecord && userHasPermission([[CREATE_REPORTS_PERMISSION]])) ||
      (!props.isCreatingNewRecord && userHasPermission([[UPDATE_REPORTS_PERMISSION]]))
    );
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.isCreatingNewRecord ? "Create Report" : "Edit Report"}</h5>
        </ModalHeader>

        <ModalBody>
          <div className="mx-2 mt-3">
            <label className="mb-3">Name</label>
            <input
              data-test="report-modal-name-input"
              className="form-control mx-auto mb-4"
              type="text"
              maxLength={MAX_REPORT_NAME_LENGTH}
              value={name}
              disabled={!formIsEditable()}
              onChange={(e) => setName(e.target.value)}
            />

            {/* Toggle email sending on or off. */}
            <div className={`${styles.switch} my-4`}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={enabled}
                  disabled={!formIsEditable()}
                  onChange={() => setEnabled((prev) => !prev)}
                />
                {enabled ? (
                  <label onClick={() => setEnabled((prev) => !prev)}>
                    This report will be emailed at the scheduled times.
                  </label>
                ) : (
                  <label onClick={() => setEnabled((prev) => !prev)}>Sending emails is disabled for this report.</label>
                )}
              </div>
            </div>

            {/* Report type. */}
            <label className="mb-3">Report Type</label>
            <select
              data-test="report-modal-report-type-select"
              className="form-select mb-4"
              value={type}
              disabled={!formIsEditable()}
              onChange={(e) => setType(e.target.value)}
            >
              <option value={REPORT_MODE_CURRENT}>Display data at the time that the report is generated.</option>
              <option value={REPORT_MODE_DIFFERENCE}>
                Display difference in the data since a specified time and when the report was generated.
              </option>
              <option value={REPORT_MODE_SERIES}>
                Display time series data from a specified time to the point when the report was generated.
              </option>
            </select>

            {/* Select the window for the report range. */}
            {type === REPORT_MODE_DIFFERENCE && (
              <select
                className="form-select mb-5"
                value={String(hours)}
                disabled={!formIsEditable()}
                onChange={(e) => setHours(parseInt(e.target.value, 10))}
              >
                <option value="1">Difference over 1 hour.</option>
                <option value="6">Difference over 6 hours.</option>
                <option value="12">Difference over 12 hours.</option>
                <option value="24">Difference over 1 day.</option>
                <option value="72">Difference over 3 days.</option>
                <option value="168">Difference over 7 days.</option>
                {(userHasCrossCompanyAccess() || String(hours) === "720") && (
                  <option value="720">Difference over 30 days.</option>
                )}
              </select>
            )}

            {type === REPORT_MODE_SERIES && (
              <select
                className="form-select mb-5"
                value={String(hours)}
                disabled={!formIsEditable()}
                onChange={(e) => setHours(parseInt(e.target.value, 10))}
              >
                <option value="1">1 hour time series.</option>
                <option value="6">6 hour time series.</option>
                <option value="12">12 hour time series.</option>
                <option value="24">1 day time series.</option>
                <option value="72">3 day time series.</option>
                <option value="168">7 day time series.</option>
                {(userHasCrossCompanyAccess() || String(hours) === "720") && (
                  <option value="720">30 day time series.</option>
                )}
              </select>
            )}

            <label className="mb-3">Assets to Report</label>
            <select
              data-test="report-modal-asset-to-report-select"
              className="form-select mb-4"
              value={assetSelectionType}
              disabled={!formIsEditable()}
              onChange={(e) => setAssetSelectionType(e.target.value)}
            >
              <option value={REPORT_ASSET_ALL}>Include all company assets in the report.</option>
              <option value={REPORT_ASSET_BY_GROUP}>Include assets from specific asset groups in the report.</option>
            </select>

            {/* Asset groups. */}
            {assetSelectionType === REPORT_ASSET_BY_GROUP && (
              <div className={styles.border}>
                <div className="mx-1">
                  <label className={styles.title}>
                    <span>Asset Groups&nbsp;</span>
                    <IconTooltip
                      id="asset-group-tooltip"
                      icon="info-circle"
                      message="The email that is sent out will include details about all assets in
                      any included asset groups."
                      color="var(--info-tooltip)"
                    />
                  </label>

                  <button
                    data-test="report-modal-add-asset-group-button"
                    type="submit"
                    className={`${styles.addButton} btn btn-success float-end`}
                    disabled={!formIsEditable()}
                    onClick={() => addAssetgroup()}
                  >
                    <span className="d-none d-sm-inline">Add Asset Group</span>
                    <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                  </button>
                </div>

                {assetgroups.map((assetgroup, i) => (
                  <ReportAssetgroupForm
                    key={assetgroup.formId}
                    formIndex={i}
                    formId={assetgroup.formId}
                    name={assetgroup.name}
                    assetgroups={props.assetgroups}
                    disabled={!formIsEditable()}
                    onChange={(formId, name) => {
                      updateAssetgroup(formId, name);
                    }}
                    onDelete={(formId) => deleteAssetgroup(formId)}
                  />
                ))}
              </div>
            )}

            {/* Email addresses. */}
            <div className={styles.border}>
              <div className="mx-1">
                <label className={styles.title}>
                  <span>Email Addresses&nbsp;</span>
                  <IconTooltip
                    id="email-address-tooltip"
                    icon="info-circle"
                    message="This is a list of the email addresses that will receive this report."
                    color="var(--info-tooltip)"
                  />
                </label>

                <button
                  data-test="report-modal-add-email-button"
                  type="submit"
                  className={`${styles.addButton} btn btn-success float-end`}
                  disabled={!formIsEditable()}
                  onClick={() => addEmailAddress()}
                >
                  <span className="d-none d-sm-inline">Add Email Address</span>
                  <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                </button>
              </div>

              {emailAddresses.map((emailAddress, i) => (
                <ReportEmailForm
                  key={emailAddress.formId}
                  formIndex={i}
                  formId={emailAddress.formId}
                  value={emailAddress.value}
                  disabled={!formIsEditable()}
                  onChange={(formId, value) => {
                    updateEmailAddress(formId, value);
                  }}
                  onDelete={(formId) => deleteEmailAddress(formId)}
                />
              ))}
            </div>

            {/* Attributes. */}
            <div className={styles.border}>
              <div className="mx-1">
                <label className={styles.title}>
                  <span>Attributes&nbsp;</span>
                  <IconTooltip
                    id="attribute-tooltip"
                    icon="info-circle"
                    message={`The attributes selected will determine what data to show for each asset
                      on the report. A report is limited to containing at most ${MAX_REPORT_ATTRIBUTES}
                       attributes.`}
                    color="var(--info-tooltip)"
                  />
                </label>

                <button
                  data-test="report-modal-add-attribute-button"
                  type="submit"
                  className={`${styles.addButton} btn btn-success float-end`}
                  onClick={() => addAttribute()}
                  disabled={attributes.length >= MAX_REPORT_ATTRIBUTES || !formIsEditable()}
                >
                  <span className="d-none d-sm-inline">Add Attribute</span>
                  <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                </button>
              </div>

              {attributes.map((attribute, i) => (
                <ReportAttributeForm
                  key={attribute.formId}
                  formIndex={i}
                  formId={attribute.formId}
                  name={attribute.name}
                  attributes={props.attributes}
                  disabled={!formIsEditable()}
                  onChange={(formId, name) => {
                    updateAttribute(formId, name);
                  }}
                  onDelete={(formId) => deleteAttribute(formId)}
                />
              ))}
            </div>

            {/* Schedule. */}
            <ScheduleForm
              scheduleSettings={scheduleSettings}
              disabled={!formIsEditable()}
              onChange={(scheduleSettings) => setScheduleSettings(scheduleSettings)}
            />

            {errorMessage.length > 0 && (
              <div className="mt-4">
                <Error message={errorMessage} />
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.isCreatingNewRecord ? (
            <Fragment>
              <button
                data-test="report-modal-create-report-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => createReport()}
              >
                Create Report
              </button>

              <button
                data-test="report-modal-cancel-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[DELETE_REPORTS_PERMISSION]]) && (
                <button
                  data-test="report-modal-delete-button"
                  className={`${styles.btn} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete Report
                </button>
              )}

              {userHasPermission([[UPDATE_REPORTS_PERMISSION]]) && (
                <button
                  data-test="report-modal-save-changes-button"
                  className={`${styles.btn} btn btn-primary`}
                  type="button"
                  onClick={() => editReport(props.reportId)}
                >
                  Save Changes
                </button>
              )}

              <button
                data-test="report-modal-close-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Close
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={`Delete '${name}'`}
        content={`Are you sure that you want to delete the report '${name}'?`}
        yesText="Delete Report"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteReport(props.reportId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

ReportModal.propTypes = {
  isCreatingNewRecord: PropTypes.bool.isRequired,
  reportId: PropTypes.number.isRequired,
  assetgroups: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  isCreatingNewRecord: boolean;
  reportId: number;
  assetgroups: ReportAssetgroup[];
  attributes: Attribute[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface GetResponseBody {
  reportId: number;
  name: string;
  enabled: boolean;
  type: string;
  hours: number;
  attributes: Attribute[];
  assetgroups: ReportAssetgroup[];
  emailAddresses: string[];
  scheduleSettings: ReportSchedule[];
  allAssets: boolean[];
}

interface PostResponseBody {
  reportId: number;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  reports?: AssetReport[];
  report?: AssetReport;
  reportId?: number;
}

interface AssetReport {
  reportId: number;
  name: string;
  enabled: boolean;
  type: string;
  hours: number;
  attributes: Attribute[];
  emailAddresses: string[];
  scheduleSettings: ReportSchedule[];
  assetgroups: ReportAssetgroup[];
}

interface ReportSchedule {
  reportScheduleId: number;
  type: string;
  value: string;
}

interface ReportAssetgroup {
  assetgroupId: number;
  name: string;
}

interface FormAttribute {
  formId: number;
  attributeId: number;
  name: string;
}

interface FormAssetgroup {
  formId: number;
  assetgroupId: number;
  name: string;
}

interface FormEmailAddress {
  formId: number;
  value: string;
}

interface Attribute {
  attributeId: number;
  name: string;
}
